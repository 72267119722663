<template>
  <vue-gauge :refid="id"
             :options="option" />
</template>
<script>
import VueGauge from "vue-gauge"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "Gauge",
  data: () => ({
    option: ""
  }),
  components: {
    VueGauge
  },
  watch: {
    indicateurs() {
      if (this.indicateurs) {
        this.option = {
          needleValue: Number(this.indicateurs),
          arcDelimiters: [30, 60, 80],
          innerNeedle: true,
          centralLabel: this.indicateurs,
          arcColors: ["rgb(140, 55, 55)", "rgb(214, 207, 17)", "rgb(54, 133, 30)", "rgb(54, 133, 30)"],
          arcLabels: ["30", "60", "80"],
          chartWidth: 190
        }
      }
    }
  },
  computed: {
    ...mapGetters(["indicateurs", "inderrors"])
  },
  methods: {
    ...mapActions(["loadIndicateurs"])
  },
  props: ["id", "url"]
}
</script>
